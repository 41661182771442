import './App.css';
import { useEffect, useState } from 'react';
import { BASE_URL } from './utils/baseURL';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function App() {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setError(null);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      toast.success(success, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setError(null);
    }
  }, [success]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true

    try {
      const response = await fetch(`${BASE_URL}users/web-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailOrPhone: emailOrPhone,
          password: password,
        }),
      });

      if (response.ok) {
        const { user, token } = await response.json();
        // Assuming you have properties like isAdmin, isVerified, isB2B on the user object

        // Save the token to localStorage or session storage
        localStorage.setItem('token', token);
        localStorage.setItem('user', user);

        setUser(user);

        setSuccess('Sign in successful');

        // Update the state or perform any other necessary actions
        setIsSignedIn(true);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    } catch (error) {
      // Handle error
      setError(error.message);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  const handleSignOut = () => {
    // Perform sign-out logic here, e.g., clearing session or tokens
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser({});
    setIsSignedIn(false);
  };

  const handleDeleteAccount = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true); // Set loading state to true

    try {
      const response = await fetch(`${BASE_URL}users/${user._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setSuccess(responseData.message); // Print success message
        handleSignOut();
      } else {
        const errorData = await response.json();
        setError(errorData.message); // Print error message
      }
    } catch (error) {
      setError('Error occurred:', error);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  return (
    <div className="container">
      <div className="card">
        {isLoading ? ( // Render loading indicator if isLoading is true
          <div className="loading">
            <div className="loader"></div>
          </div>
        ) : isSignedIn ? (
          <div>
            <h1>Welcome! You are signed in.</h1>
            <h2>To delete account, click the "Delete Account" button</h2>
            <p>Confirm account details</p>
            <p>Name: {user?.name}</p>
            <p>email: {user?.email}</p>
            <p>phone: {user?.phone}</p>
            <div className="button-group">
              <button onClick={handleSignOut} className="sign-out-button">
                Sign Out
              </button>
              <button onClick={handleDeleteAccount} className="delete-button">
                Delete Account
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSignIn}>
            <h2>Welcome to Ntopor Delete Account Page</h2>
            <p>
              Note: To delete your account login with email and password. if you
              signed in with google auth, follow the reset password procedures
              in the app to create a password then retry
            </p>
            <p>Add country code if trying to signin with phone number</p>
            <div className="form-group">
              <label>Email or Phone:</label>
              <input
                type="text"
                value={emailOrPhone}
                onChange={(e) => setEmailOrPhone(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="button-group">
              <button type="submit" className="submit-button">
                Sign In
              </button>
            </div>
          </form>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
