const env = 'prod';
const isngrok = false;
const ngrok = 'https://4ff6-154-72-169-238.ngrok-free.app/api/v1/';

export const BASE_URL =
  env === 'dev'
    ? isngrok
      ? ngrok
      : 'http://localhost:5001/api/v1/'
    : env === 'staging'
    ? 'https://ntopor-backend.onrender.com/api/v1/'
    : 'https://api.ntopor.com/api/v1/';
